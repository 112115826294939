import { createAsyncThunk } from "@reduxjs/toolkit";
import { AutomationNetworkService } from "./AutomationNetworkServices";

export class AutomationBuilderActions {
  static getApplicationsList = createAsyncThunk(
    "AutomationBuilderSlice/getApplicationsList",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.getApplicationsList()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // //* GET Email verified status //

  static verifyActiveStep = createAsyncThunk(
    "AutomationBuilderSlice/verifyActiveStep",
    (activeSteps: number, { dispatch, rejectWithValue }) => {
      return activeSteps;
    }
  );

  //*Create Automation //
  static postCreateAutomation = createAsyncThunk(
    "AutomationSlice/postCreateAutomation",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.createAutomation(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //* DELETE Automation//

  static deleteAutomation = createAsyncThunk(
    "AutomationSlice/deleteAutomation",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.deleteAutomation(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //  get broadcasting list
  static getAutomationListData = createAsyncThunk(
    "AutomationSlice/getAutomationListData",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.getAutomationList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // * GET Broadcast BY ID
  static getAutomationDataById = createAsyncThunk(
    "WhatsappSlice/getAutomationDataById",
    (id: string, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.getAutomationById(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  // * GET Config BY Type
  static getModelConfigByType = createAsyncThunk(
    "WhatsappSlice/getModelConfigByType",
    (type: string, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.getModelConfigByType(type)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // create automatiojn folder
  static CreateAutomationFolder = createAsyncThunk(
    "SMSSlice/CreateAutomationFolder",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.postAutomationFolder(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static applyAutomationFolder = createAsyncThunk(
    "SMSSlice/applyAutomationFolder",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.applyAutomationFolder(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static deleteAutomationFolder = createAsyncThunk(
    "SMSSlice/deleteAutomationFolder",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.deleteAutomationFolder(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static publishAutomationById = createAsyncThunk(
    "SMSSlice/publishAutomationById",
    (id: string, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.publishAutomationById(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static updateAutomationById = createAsyncThunk(
    "SMSSlice/updateAutomationById",
    (
      { id, data }: { id: string; data: any },
      { dispatch, rejectWithValue }
    ) => {
      return AutomationNetworkService.updateAutomationById(id, data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static unPublishAutomationById = createAsyncThunk(
    "SMSSlice/unPublishAutomationById",
    (id: string, { dispatch, rejectWithValue }) => {
      return AutomationNetworkService.unPublishAutomationById(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getDateMatchOptions = createAsyncThunk(
    "AutomationBuilderSlice/getDateMatchOptions",
    (_, { rejectWithValue }) => {
      return AutomationNetworkService.getDateMatchOptions()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
}
