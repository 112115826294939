import * as Yup from "yup";

export const EmailConfigSchema = Yup.object().shape({
  subject: Yup.string().required("Please enter a subject."),
  emailTemplateId: Yup.object()
    .shape()
    .nullable()
    .required("Please select a template."),
  emailAddress: Yup.object()
    .shape()
    .nullable()
    .required("Please select business address."),
  from: Yup.object().shape().nullable().required("Please select a template."),
  replyTo: Yup.string()
    .email("Please enter a valid email address.")
    .notRequired(),
});

export const whatsAppConfigSchema = Yup.object().shape({
  whatsappTemplateId: Yup.object()
    .shape()
    .nullable()
    .required("Please select a template."),
});

export const SmsConfigSchema = Yup.object().shape({
  smsTemplateId: Yup.object()
    .shape()
    .nullable()
    .required("Please select a template."),
  sender_id: Yup.object()
    .shape()
    .nullable()
    .required("Please select business address."),
  resolved_body_variables: Yup.array()
    .of(
      Yup.object({
        index: Yup.string()
          .min(2, "Field is a required field")
          .max(60)
          .required()
          .label("Field"),
        value: Yup.string().ensure().min(1).max(60).required().label("Value"),
      })
    )
    .label("Body variables"),
});

export const UpdateContactConfigSchema = Yup.object().shape({});

export const DelayConfigSchema = Yup.object().shape({
  delay: Yup.number()
    .required()
    .min(1, "At least one second delay is expected"),
  delayPeriod: Yup.object()
    .shape()
    .nullable()
    .required("Please select a Period."),
});

export const DateMatchSchema=Yup.object().shape({
  // name: Yup.string().required("Please write a name"),
  field_name: Yup.object().nullable().required("Please select a date field"),
  execution_cycle: Yup.object().nullable().required("Please select an execution cycle"),
  date_of_execution: Yup.object().nullable().required("Please select a date of execution"),
  execution_offset: Yup.number()
    .nullable()
    .typeError("X Days must be a number")
    .min(1, "X Days cannot be less than 1")
    .max(7, "X Days cannot be more than 7")
    .when("date_of_execution", {
      is: (val) => val?.option_name !== "On",
      then: (schema) => schema.required("Please enter the number of days"),
      otherwise: (schema) => schema.notRequired().nullable(),
    })
    .required("Please enter the number of days"), 
  execution_time: Yup.string().required("Please select a time"),
})

export const AddToListConfigSchema = Yup.object().shape({
  marketing_list_id: Yup.object()
    .shape()
    .nullable()
    .required("Please select a list."),
});

export const AppliactionSubmittedConfigSchema = Yup.object().shape({
  application_list_id: Yup.object()
    .shape()
    .nullable()
    .required("Please select a list."),
});

export const CheckEmailStatusConfigSchema = Yup.object().shape({
  email_status: Yup.object()
    .shape()
    .nullable()
    .required("Please select a list."),
});

export const CheckSMSStatusConfigSchema = Yup.object().shape({
  sms_status: Yup.object().shape().nullable().required("Please select a list."),
});

export const IsInListsConfigSchema = Yup.object().shape({
  marketingListId: Yup.object()
    .shape()
    .nullable()
    .required("Please select a list."),
});

export const AddedToListConfigSchema = Yup.object().shape({
  marketing_list_id: Yup.object()
    .shape()
    .nullable()
    .required("Please select a list."),
});

export const DateAndTimeConfigSchema = Yup.object().shape({
  date: Yup.mixed()
    .label("Date")
    .required(),
  time: Yup.mixed()
    .label("Time")
    .required()
});

export const IsInSegmentConfigSchema = Yup.object().shape({
  segmentListId: Yup.object()
    .shape()
    .nullable()
    .required("Please select an activity."),
});

export const EmailActivityConfigSchema = Yup.object().shape({
  email_activity: Yup.object()
    .shape()
    .nullable()
    .required("Please select a list."),
  emailTemplateId: Yup.object()
    .shape()
    .nullable()
    .when(["email_view"], (value, schema) => {
      return value === "Particular Email"
        ? schema.required("Please select a template.")
        : schema.optional();
    }),
});

export const SmsActivityConfigSchema = Yup.object().shape({
  sms_activity: Yup.object()
    .shape()
    .nullable()
    .required("Please select a list."),
  smsTemplateId: Yup.object()
    .shape()
    .nullable()
    .when(["sms_view"], (value, schema) => {
      return value === "Particular SMS"
        ? schema.required("Please select a template.")
        : schema.optional();
    }),
});

export const AddedToSegmentConfigSchema = Yup.object().shape({
  segmentId: Yup.object().shape().nullable().required("Please select a list."),
});
