import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import debounce from "lodash/debounce";
import parse from "html-react-parser";

// components
import Input from "../../../Components/Input";
import InteractiveMessageBody from "../InteractiveMessage/InteractiveMessageBody";

// slice
import {
  getLanguage,
  getActiveNode,
  updateModifiedData,
} from "../../../Slices/main.slice";
import { useAppDispatch } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";

// utils
import { getNodeConfig, getInteractiveMessageData } from "../../../Utils";
import {
  convertWhatsappToHtmlText,
  getChannelWiseText,
  getProcessedLength,
  getPlainTextData,
  getInitialEditiorText,
} from "../../../Utils/editor";
import styled from "styled-components";

const DelayComponent = (props: NodeProps) => {
  const { node } = props;
  const language = useSelector(getLanguage);
  const { interactive } = getInteractiveMessageData(node, language);
  const [body, updateBody] = useState(
    convertWhatsappToHtmlText(interactive?.body) || ""
  );
  const [delay, setDelay] = useState(interactive?.delay || 5);
  const activeNode = useSelector(getActiveNode);
  const currentBot = useSelector(useCurrentBot);
  const nodeConfig = getNodeConfig(node.subType);
  const validation = currentBot?.channel
    ? nodeConfig.validation[currentBot?.channel]
    : {};
  const charLimit = validation?.charLimit;
  const dispatch = useAppDispatch();
  const CHARACTER_LIMITS = {
    body: validation?.bodyMaxLimit,
  };
  const isActiveNode = activeNode === node.nodeId;

  const handleDebounceFn = (body: string, delayValue: number) => {
    const data = {
      value: getChannelWiseText(body, "whatsapp"),
      delay: delayValue,
    };
    dispatch(
      updateModifiedData([{ ...data, type: "delay" }])
    );
  };

  const debounceFn = useCallback(handleDebounceFn, []);

  // Add useEffect to trigger updateNode when delay changes
  useEffect(() => {
    if (isActiveNode) {
      // This will trigger the updateNode function which will call updateDelay
      const data = {
        value: getChannelWiseText(body, "whatsapp"),
        delay: delay,
        type: "delay"
      };
      dispatch(updateModifiedData([data]));
    }
  }, [delay, isActiveNode, body, dispatch]);

  const handleBodyTextChange = (bodyText: string) => {
    updateBody(bodyText);
    debounceFn(bodyText, delay);
  };

  const handleDelayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value) || 0;
    if (value < 0 || value > 10) return;
    setDelay(value);
    debounceFn(body, value);
  };

  return (
    <Box sx={{ p: "16px" }}>
      {isActiveNode ? (
        <>
          <InputLabel
            shrink
            htmlFor="body"
            sx={{ position: "static", transform: "none" }}
          >
            Message
          </InputLabel>
          <InteractiveMessageBody
            text={body}
            maxTextLimit={CHARACTER_LIMITS?.body}
            onChange={handleBodyTextChange}
            error={false}
          />
          <InputLabel
            shrink
            htmlFor="delay"
            sx={{ position: "static", transform: "none", mt: 2 }}
          >
            Delay (in seconds) <sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <InputWrapper>
          <Input
            id="delay"
            type="text"
            placeholder="Enter delay in seconds"
            onChange={handleDelayChange}
            value={delay}
            inputProps={{ min: 0, max: 10 }}
            />
            </InputWrapper>
        </>
      ) : (
        <>
          {body && body !== "" && (
            <Typography sx={{
              wordBreak: "break-all"
            }} variant="body1">{parse(body)}</Typography>
          )}
          <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
            Delay: {delay} seconds
          </Typography>
        </>
      )}
    </Box>
  );
};

export default DelayComponent;

const InputWrapper = styled.div`
.MuiInputBase-input {
padding-left: 8px !important;
}
`;