import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppForm, AppFormDatePicker, AppFormObserver, AppFormTimePickerMui, AppSubmitButton } from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import { marketingListActions } from "../../../Store/Slices/MarketingList/marketingList.actions";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { AddedToListConfigSchema, DateAndTimeConfigSchema } from "../validationSchema";
import { withLoader } from "../../../AppComponents/Loader";
import { convertDateTimeToUTC } from "../../Broadcast/WhatsApp/Utils/utils";

const DateTimeTrigger = ({ setLoading }: any): JSX.Element => {
  setLoading(false)
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formRef: any = useRef();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [initialValues, setInitialValues]: any = useState({
    date: "",
    time: ""
  });
  const [formikProps, setFormikProps] = useState<any>(null);
  const [dateTime, setDateTime] = useState<any>("");
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  console.log("automationPayload",automationPayload,automationReduxData);
  
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.trigger_date
    ) {
      //function to filter object based on Value
      setInitialValues({
        date : new Date( automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.trigger_date),
        time : new Date( automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.trigger_date)
      });
      const triggerDate = new Date(
        automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.trigger_date
      );
      
      const hours = triggerDate.getHours();
      const minutes = String(triggerDate.getMinutes()).padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = ((hours + 11) % 12) + 1; // Converts 24-hour to 12-hour format
      
      const formattedDateTime = `${triggerDate.getFullYear()}-${String(
        triggerDate.getMonth() + 1
      ).padStart(2, "0")}-${String(triggerDate.getDate()).padStart(2, "0")} ${formattedHours}:${minutes} ${ampm}`;
      
      setDateTime(formattedDateTime);
    }
  }, [automationPayload]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values: any, submitProps: any) => {
    const utcDateTime = convertDateTimeToUTC(values?.date, values?.time);
    const triggerDate = new Date(utcDateTime);

    const hours = triggerDate.getHours();
    const minutes = String(triggerDate.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12) || 12; // Ensures 12-hour format correctly

    const formattedDateTime = `${triggerDate.getFullYear()}-${String(triggerDate.getMonth() + 1).padStart(2, "0")}-${String(triggerDate.getDate()).padStart(2, "0")} ${formattedHours}:${minutes} ${ampm}`;

    setDateTime(formattedDateTime);

    let payload: any = {
      nodeObject: {
        trigger_date: convertDateTimeToUTC(
          values?.date,
          values?.time
        ),
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText : formattedDateTime || dateTime
    };
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("date", "time", "", { shouldDirty: true })
  }, []);
  return (
    <ModelFormWrapper>
      <Typography variant="h6">Date and Time Schedule</Typography>
          <AppForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={DateAndTimeConfigSchema}
            innerRef={formRef}
          >
            <AppFormDatePicker name={"date"} label="Select date " minDate={new Date()} />
            <AppFormTimePickerMui
              name={"time"}
              label="Select time "
              utcTime={true}
              disablePast={true}
            />
            <AppFormObserver setFormikProps={setFormikProps} />
            <ModelFooter>
              <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
              <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
            </ModelFooter>
          </AppForm>
    </ModelFormWrapper>
  );
};

export default withLoader(DateTimeTrigger);

const ModelFormWrapper = styled.div`
width: ${(props: any) => props.isDataAvaliable ? "400px" : "500px"};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
