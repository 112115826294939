import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AutomationBuilderActions } from "./automationBuilder.actions";
// import { broadcastActions } from "./broadcast.actions";
interface initialStateTypes {
  data: {
    selectedNodeObject: any;
    selectedNodeId: string | null;
    selectedModelId: string | null;
    actionCallback: Function | null;
    targetedNodeObject: Object;
    targetNodeId: string | null;
    targetedModelId: string | null;
    showModel: boolean;
    modelType: string | null;
    listitems: any;
    count?: number;
    automationData: any;
    modelConfigData: any;
    folderListOptionsData: any;
    isAutomationUpdated: any;
    isAutomationPublished: any;
    applicationsList: any;
    dateMatchField:any;
  };
  payload: any;
  error: string;
  getAutomationError: string;
  modelConfigError: string;
  automationUpdateError: string;
  loading: boolean;

}

const initialState: initialStateTypes = {
  data: {
    selectedNodeObject: {},
    selectedNodeId: null,
    selectedModelId: null,
    targetedNodeObject: {},
    targetNodeId: null,
    targetedModelId: null,
    actionCallback: null,
    showModel: false,
    modelType: null,
    listitems: [],
    count: 0,
    automationData: null,
    modelConfigData: null,
    folderListOptionsData: null,
    isAutomationUpdated: false,
    isAutomationPublished: false,
    applicationsList: null,
    dateMatchField:[]
  },
  payload: {
    draftedUIObject: {
      uiObject: {},
      graphData: {
        triggerObjects: [],
        nodes: [],
      },
    },
  },
  error: "",
  modelConfigError: "",
  getAutomationError: "",
  automationUpdateError: "",
  loading: false,
};

const AutomationBuilderSlice = createSlice({
  name: "AutomationBuilderSlice",
  initialState,
  reducers: {
    showConfigModal: (state: any, action: PayloadAction<any>) => {
      state.data.selectedNodeObject = action.payload;
      state.data.selectedNodeId = action.payload.el.getAttribute("model-id");
      state.data.modelType = action.payload.model.attributes.type.split(".")[1];
      state.data.showModel = true;
      return state;
    },
    closeConfigModal: (state: any, action?: PayloadAction<any>) => {
      state.data.showModel = false;
      return state;
    },
    setTargetedNode: (state: any, action: PayloadAction<any>) => {
      state.data.targetedNodeObject = action.payload;
      state.data.targetNodeId = action.payload?.cid;
      state.data.targetedModelId = action.payload?.id;
      return state;
    },
    setNodeConfig: (state: any, action: PayloadAction<any>) => {
      // Use when you need to set node configuration as class/function
      if (
        !state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId]
      ) {
        state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId] =
          {};
      }
      if (action.payload?.key) {
        state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId] = {
          ...state.payload.draftedUIObject.graphData.nodes[
            action.payload.nodeId
          ],
          [action?.payload.key]: action?.payload[action?.payload?.key],
        };
      }

      state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId] = {
        ...state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId],
        nodeObjectData: { ...action.payload.nodeObject },
      };
      state.data.selectedNodeObject.model.attributes.markup.find(
        (ele: any) => ele.tagName === "foreignObject",
      ).children[0].textContent = action.payload.configText;
      state.data.selectedNodeObject.el.childNodes[3].childNodes[0].textContent =
        action.payload.configText;
      state.data.selectedNodeObject.$el[0].childNodes[3].childNodes[0].textContent =
        action.payload.configText;
      state.data.selectedNodeObject.resize();
      return state;
    },
    setNewNode: (state: any, action: PayloadAction<any>) => {
      if (action.payload.type === "trigger") {
        state.payload.draftedUIObject.graphData.triggerObjects = [
          ...state.payload.draftedUIObject.graphData.triggerObjects,
          action.payload.nodeId,
        ];
      }
      if (
        !state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId]
      ) {
        state.payload.draftedUIObject.graphData.nodes = {
          ...state.payload.draftedUIObject.graphData.nodes,
          [action.payload.nodeId]: {},
        };
      }
      state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId] = {
        type: action.payload.type,
        class: action.payload.class,
        function: action.payload.function,
      };
      return state;
    },
    setLinkNode: (state: any, action: PayloadAction<any>) => {
      if (
        !state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId]
      ) {
        return state;
      }
      if (
        !state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId]
          .edges
      ) {
        state.payload.draftedUIObject.graphData.nodes[
          action.payload.nodeId
        ].edges = [];
      }
      state.payload.draftedUIObject.graphData.nodes[
        action.payload.nodeId
      ].edges = [
        ...state.payload.draftedUIObject.graphData.nodes[action.payload.nodeId]
          .edges,
        ...action.payload.edges,
      ];
    },
    setGraphUI: (state: any, action: PayloadAction<any>) => {
      state.payload.draftedUIObject.uiObject = action.payload;
    },
    removeElementFromPayload: (state: any, action: PayloadAction<any>) => {
      if (action.payload.attributes.type.indexOf("Trigger") > -1) {
        state.payload.draftedUIObject.graphData.triggerObjects =
          state.payload.draftedUIObject.graphData.triggerObjects.filter(
            (ele: any) => ele !== action.payload.id,
          );
      }
      if (state.payload.draftedUIObject.graphData.nodes[action.payload.id]) {
        delete state.payload.draftedUIObject.graphData.nodes[action.payload.id];
      }
      return state;
    },
    removeLinkFromPayload: (state: any, action: PayloadAction<any>) => {
      if (
        action.payload.attributes?.source?.id &&
        action.payload.attributes?.target?.id
      ) {
        if (
          state.payload.draftedUIObject.graphData.nodes[
            action.payload.attributes.source.id
          ]
        ) {
          state.payload.draftedUIObject.graphData.nodes[
            action.payload.attributes.source.id
          ].edges = state.payload.draftedUIObject.graphData.nodes[
            action.payload.attributes.source.id
          ].edges.filter(
            (ele: any) => ele.to !== action.payload.attributes.target.id,
          );
        }
      }
      return state;
    },
    removeAutomationData: (state: any, action: PayloadAction<any>) => {
      state.data.automationData = null;
      state.data.payload = {
        draftedUIObject: {
          uiObject: {},
          graphData: {
            triggerObjects: [],
            nodes: [],
          },
        },
      };
      return state;
    },
    resetDelete: (state: any, action?: PayloadAction<any>) => {
      state.data.isDeleted = false;
      state.data.isAutomationPublished = false;
      state.data.isAutomationUpdated = false;
      state.automationUpdateError = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder

      // * get Email verified status
      .addCase(
        AutomationBuilderActions.verifyActiveStep.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.verifyActiveStep.fulfilled,
        (state, action) => {},
      )
      .addCase(
        AutomationBuilderActions.verifyActiveStep.rejected,
        (state, error: any) => {},
      )
      //* Create Automation Post
      .addCase(
        AutomationBuilderActions.postCreateAutomation.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.postCreateAutomation.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        AutomationBuilderActions.postCreateAutomation.rejected,
        (state, error: any) => {
          state.loading = false;
          state.error = error?.message;
        },
      )

      //* Get Automation List Data
      .addCase(
        AutomationBuilderActions.getAutomationListData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.getAutomationListData.fulfilled,
        (state, action) => {
          state.data.listitems = action.payload.data;
          state.data.count = action.payload.data?.count;
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        AutomationBuilderActions.getAutomationListData.rejected,
        (state, error: any) => {
          state.loading = false;
          state.error = error?.message;
        },
      )

      //* Get Automation By ID
      .addCase(
        AutomationBuilderActions.getAutomationDataById.pending,
        (state, action) => {
          state.getAutomationError = "";
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.getAutomationDataById.fulfilled,
        (state, action) => {
          state.data.automationData = action.payload.data?.Data;
          if (action.payload.data?.Data?.draftedUIObject?.uiObject) {
            state.payload.draftedUIObject =
              action.payload.data?.Data?.draftedUIObject;
          }
          state.getAutomationError = "";
          state.loading = false;
        },
      )
      .addCase(
        AutomationBuilderActions.getAutomationDataById.rejected,
        (state, error: any) => {
          state.loading = false;
          state.data.automationData = null;
          state.getAutomationError = error?.message || "Something went wrong";
        },
      )

      //* Get Model Config By Type
      .addCase(
        AutomationBuilderActions.getModelConfigByType.pending,
        (state, action) => {
          state.modelConfigError = "";
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.getModelConfigByType.fulfilled,
        (state, action) => {
          const emilListArray: Array<{ id: string; name: string }> = [];
          let payload: any = action?.payload?.data;
          if (action?.payload?.data?.email_list?.length > 0) {
            action?.payload?.data?.email_list.map((email: any) => {
              emilListArray.push({
                id: email,
                name: email,
              });
            });
            payload.email_list = emilListArray;
          }
          state.data.modelConfigData = payload;
          state.modelConfigError = "";
          state.loading = false;
        },
      )
      .addCase(
        AutomationBuilderActions.getModelConfigByType.rejected,
        (state, error: any) => {
          state.loading = false;
          state.data.modelConfigData = null;
          state.modelConfigError = error?.message || "Something went wrong";
        },
      )
      //* Update Automation By ID
      .addCase(
        AutomationBuilderActions.updateAutomationById.pending,
        (state, action) => {
          state.modelConfigError = "";
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.updateAutomationById.fulfilled,
        (state, action) => {
          if (action.payload.data.status === "automation updated") {
            state.data.isAutomationUpdated = true;
          } else {
            state.data.isAutomationUpdated = false;
          }
          state.automationUpdateError = "";
          state.loading = false;
        },
      )
      .addCase(
        AutomationBuilderActions.updateAutomationById.rejected,
        (state, error: any) => {
          state.loading = false;
          state.data.isAutomationUpdated = false;
          state.automationUpdateError =
            error?.message || "Something went wrong";
        },
      )
      //* Update Automation By ID
      .addCase(
        AutomationBuilderActions.publishAutomationById.pending,
        (state, action) => {
          state.modelConfigError = "";
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.publishAutomationById.fulfilled,
        (state, action) => {
          if (action.payload.data.Status === "Success") {
            state.data.isAutomationPublished = true;
          } else {
            state.data.isAutomationPublished = false;
          }
          state.automationUpdateError = "";
          state.loading = false;
        },
      )
      .addCase(
        AutomationBuilderActions.publishAutomationById.rejected,
        (state, error: any) => {
          state.loading = false;
          state.data.isAutomationPublished = false;
          state.automationUpdateError =
            error?.payload?.data?.error || "Something went wrong";
        },
      )
      //* Get Applications List
      .addCase(
        AutomationBuilderActions.getApplicationsList.pending,
        (state, action) => {
          state.data.applicationsList = null;
          state.loading = true;
        },
      )
      .addCase(
        AutomationBuilderActions.getApplicationsList.fulfilled,
        (state, action) => {
          if (action.payload.data.Status === true) {
            state.loading = false;
            state.data.applicationsList = action.payload.data.data;
          } else {
            state.loading = false;
            state.data.applicationsList = null;
          }
        },
      )
      .addCase(
        AutomationBuilderActions.getApplicationsList.rejected,
        (state, error: any) => {
          state.loading = false;
          state.data.isAutomationPublished = false;
          state.automationUpdateError =
            error?.message || "Something went wrong";
        },
      )
      // 
      .addCase(AutomationBuilderActions.getDateMatchOptions.pending, (state, action) => {
        state.data.dateMatchField = null;
      })
      .addCase(AutomationBuilderActions.getDateMatchOptions.fulfilled, (state, action) => {
        state.data.dateMatchField = action.payload?.data.data;
      })
      .addCase(AutomationBuilderActions.getDateMatchOptions.rejected, (state, action) => {
        state.data.dateMatchField = null;
      });
  },
});
export const {
  removeAutomationData,
  resetDelete,
  showConfigModal,
  closeConfigModal,
  setTargetedNode,
  setNodeConfig,
  setNewNode,
  setLinkNode,
  setGraphUI,
  removeElementFromPayload,
  removeLinkFromPayload,
} = AutomationBuilderSlice.actions;

export default AutomationBuilderSlice.reducer;
