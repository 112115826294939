import AddToList from "../../assets/images/icons/AddToList.svg";
import AddToListIcon from "../../assets/images/icons/AddToListIcon.svg";
import AddedToSegment from "../../assets/images/icons/AddedToSegment.svg";
import CheckContactField from "../../assets/images/icons/CheckContactField.svg";
import CheckEmailStatus from "../../assets/images/icons/CheckEmailStatus.svg";
import CheckSmsStatus from "../../assets/images/icons/CheckSmsStatus.svg";
import ContactCreatedIcon from "../../assets/images/icons/ContactCreatedIcon.svg";
import ContactFieldMatch from "../../assets/images/icons/ContactFieldMatch.svg";
import ContactUpdate from "../../assets/images/icons/ContactUpdate.svg";
import ContactUpdateIcon from "../../assets/images/icons/ContactUpdateIcon.svg";
import DelayIcon from "../../assets/images/icons/DelayIcon.svg";
import EmailActivityIcon from "../../assets/images/icons/EmailActivityIcon.svg";
import EmailToUser from "../../assets/images/icons/EmailToUser.svg";
import IsInList from "../../assets/images/icons/IsInList.svg";
import IsInSegment from "../../assets/images/icons/IsInSegment.svg";
import RemoveFromList from "../../assets/images/icons/RemoveFromList.svg";
import RemoveFromListIcon from "../../assets/images/icons/RemoveFromListIcon.svg";
import SMSActivityIcon from "../../assets/images/icons/SMSActivityIcon.svg";
import SendEmailIcon from "../../assets/images/icons/SendEmailIcon.svg";
import SMSIcon from "../../assets/images/icons/SmsIcon.svg";
import SmsToTheUser from "../../assets/images/icons/SmsToTheUser.svg";
import EmailUnsubscribeIcon from "../../assets/images/icons/emailUnsubscribe.svg";
import AppliactionSubmittedIcon from "../../assets/images/icons/applicationSubmit.svg";
import DateFieldMatch from "../../assets/images/icons/Date-Field-Match.svg"
import SendWhatsApp from "../../assets/images/icons/SendWhatsApp.svg";
import SendQrIcon from "../../assets/images/icons/SendQrIcon.svg";

const joint = require("jointjs");
const { util } = joint;

export const automationNodeOptions = {
  Trigger: [
    // {
    //   name: "Whatsapp Template",
    //   dataType: "Trigger.whatsapp-template",
    //   path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
    //   fill: "#36B37E",
    //   transform: "scale(0.18)",
    //   icon: <UnsubscribeIcon />,
    // },
    // {
    //   name: "Unsubscribe Email",
    //   dataType: "Trigger.email-unsubscribe",
    //   path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
    //   fill: "#36B37E",
    //   transform: "scale(0.18)",
    //   icon: EmailUnsubscribeIcon,
    // },
    // {
    //   name: "Email Activity",
    //   dataType: "Trigger.email-activity",
    //   path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
    //   fill: "#36B37E",
    //   transform: "scale(0.18)",
    //   icon: EmailActivityIcon,
    // },
    // {
    //   name: "SMS Activity",
    //   dataType: "Trigger.sms-activity",
    //   path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
    //   fill: "#36B37E",
    //   transform: "scale(0.18)",
    //   icon: SMSActivityIcon,
    // },
    {
      name: "Contact Update",
      dataType: "Trigger.contact-updated",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: ContactUpdate,
      permission: "automation",
    },
    {
      name: "Contact Created",
      dataType: "Trigger.contact-added",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: ContactCreatedIcon,
      permission: "automation",
    },
    {
      name: "Added To List",
      dataType: "Trigger.contact-added_to_list",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: AddToList,
      permission: "automation",
    },
    {
      name: "Remove From List",
      dataType: "Trigger.contact-removed_from_list",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: RemoveFromList,
      permission: "automation",
    },
    {
      name: "Added to Segment",
      dataType: "Trigger.segment-added_to_segment",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: AddedToSegment,
      permission: "automation",
    },
    {
      name: "Contact Field Match",
      dataType: "Trigger.contact-field_match",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: ContactFieldMatch,
      permission: "automation",
    },
    {
      name:"Date Field Match",
      dataType: "Trigger.Datematch-date_match",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: DateFieldMatch,
      permission: "automation",

    },
    {
      name: "Applications Submit",
      dataType: "Trigger.application-submitted",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: AppliactionSubmittedIcon,
      permission: "application_forms",
    },
    {
      name: "Date and Time Schedule",
      dataType: "Trigger.datetime-scheduler",
      path: "M147.5,12.7c71.2,0,129,57.8,129,129s-57.8,129-129,129s-129-57.8-129-129S76.3,12.7,147.5,12.7z",
      fill: "#36B37E",
      transform: "scale(0.18)",
      icon: AppliactionSubmittedIcon,
      permission: "automation",
    }
  ],
  Condition: [
    {
      name: "Check Email Status",
      dataType: "Condition.email-checkStatus",
      path: "M99.5,0H50.5c-4.2,0-8.2,1.7-11.2,4.6L4.6,39.3c-3,3-4.6,7-4.6,11.2v49.1c0,4.2,1.7,8.2,4.6,11.2l34.7,34.7 c3,3,7,4.6,11.2,4.6h49.1c4.2,0,8.2-1.7,11.2-4.6l34.7-34.7c3-3,4.6-7,4.6-11.2V50.5c0-4.2-1.7-8.2-4.6-11.2L110.7,4.6 C107.7,1.7,103.7,0,99.5,0z",
      fill: "#505F79",
      transform: "scale(0.32)",
      icon: CheckEmailStatus,
      permission: "automation",
    },
    {
      name: "Check SMS Status",
      dataType: "Condition.sms-checkStatus",
      path: "M99.5,0H50.5c-4.2,0-8.2,1.7-11.2,4.6L4.6,39.3c-3,3-4.6,7-4.6,11.2v49.1c0,4.2,1.7,8.2,4.6,11.2l34.7,34.7 c3,3,7,4.6,11.2,4.6h49.1c4.2,0,8.2-1.7,11.2-4.6l34.7-34.7c3-3,4.6-7,4.6-11.2V50.5c0-4.2-1.7-8.2-4.6-11.2L110.7,4.6 C107.7,1.7,103.7,0,99.5,0z",
      fill: "#505F79",
      transform: "scale(0.32)",
      icon: CheckSmsStatus,
      permission: "automation",
    },
    {
      name: "Check Contact Field",
      dataType: "Condition.contact-query",
      path: "M99.5,0H50.5c-4.2,0-8.2,1.7-11.2,4.6L4.6,39.3c-3,3-4.6,7-4.6,11.2v49.1c0,4.2,1.7,8.2,4.6,11.2l34.7,34.7 c3,3,7,4.6,11.2,4.6h49.1c4.2,0,8.2-1.7,11.2-4.6l34.7-34.7c3-3,4.6-7,4.6-11.2V50.5c0-4.2-1.7-8.2-4.6-11.2L110.7,4.6 C107.7,1.7,103.7,0,99.5,0z",
      fill: "#505F79",
      transform: "scale(0.32)",
      icon: CheckContactField,
      permission: "automation",
    },
    {
      name: "Is In List",
      dataType: "Condition.list-query",
      path: "M99.5,0H50.5c-4.2,0-8.2,1.7-11.2,4.6L4.6,39.3c-3,3-4.6,7-4.6,11.2v49.1c0,4.2,1.7,8.2,4.6,11.2l34.7,34.7 c3,3,7,4.6,11.2,4.6h49.1c4.2,0,8.2-1.7,11.2-4.6l34.7-34.7c3-3,4.6-7,4.6-11.2V50.5c0-4.2-1.7-8.2-4.6-11.2L110.7,4.6 C107.7,1.7,103.7,0,99.5,0z",
      fill: "#505F79",
      transform: "scale(0.32)",
      icon: IsInList,
      permission: "automation",
    },
    {
      name: "Is In Segment",
      dataType: "Condition.segment-query",
      path: "M99.5,0H50.5c-4.2,0-8.2,1.7-11.2,4.6L4.6,39.3c-3,3-4.6,7-4.6,11.2v49.1c0,4.2,1.7,8.2,4.6,11.2l34.7,34.7 c3,3,7,4.6,11.2,4.6h49.1c4.2,0,8.2-1.7,11.2-4.6l34.7-34.7c3-3,4.6-7,4.6-11.2V50.5c0-4.2-1.7-8.2-4.6-11.2L110.7,4.6 C107.7,1.7,103.7,0,99.5,0z",
      fill: "#505F79",
      transform: "scale(0.32)",
      icon: IsInSegment,
      permission: "automation",
    },
    {
      name: "Whatsapp active bot session",
      dataType: "Condition.whatsapp-check_active_session",
      path: "M99.5,0H50.5c-4.2,0-8.2,1.7-11.2,4.6L4.6,39.3c-3,3-4.6,7-4.6,11.2v49.1c0,4.2,1.7,8.2,4.6,11.2l34.7,34.7 c3,3,7,4.6,11.2,4.6h49.1c4.2,0,8.2-1.7,11.2-4.6l34.7-34.7c3-3,4.6-7,4.6-11.2V50.5c0-4.2-1.7-8.2-4.6-11.2L110.7,4.6 C107.7,1.7,103.7,0,99.5,0z",
      fill: "#505F79",
      transform: "scale(0.32)",
      icon: IsInSegment,
      permission: "automation",
    },
  ],
  Action: [
    {
      name: "Send Email",
      dataType: "Action.email-send",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: SendEmailIcon,
    },
    {
      name: "Add Delay",
      dataType: "Action.delay-add",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: DelayIcon,
    },
    {
      name: "Send SMS",
      dataType: "Action.sms-send",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: SMSIcon,
    },
    {
      name: "Update Contact",
      dataType: "Action.contact-update",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: ContactUpdateIcon,
    },
    {
      name: "Email to User",
      dataType: "Action.email-send_user",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: EmailToUser,
    },
    {
      name: "SMS to User",
      dataType: "Action.sms-send_user",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: SmsToTheUser,
    },
    {
      name: "Add to List",
      dataType: "Action.list-add",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: AddToListIcon,
    },
    {
      name: "Remove From List",
      dataType: "Action.list-remove",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: RemoveFromListIcon,
    },
    {
      name: "Send Whatsapp",
      dataType: "Action.whatsapp-send",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: SendWhatsApp,
    },
    {
      name: "Send QR to Contact",
      dataType: "Action.qrclass-can_generate_qr_code",
      path: "M264.5,270.7H30.4c-6.6,0-12-5.4-12-12v-234c0-6.6,5.4-12,12-12h234.1c6.6,0,12,5.4,12,12v234.1C276.5,265.3,271.1,270.7,264.5,270.7z",
      fill: "#4C9AFF",
      transform: "scale(0.18)",
      icon: SendQrIcon,
      permission: "can_generate_qr_code",
    },
  ],
};

export const ShapeConfigurations = {
  Trigger: {
    "whatsapp-template": {
      title: "Send Tempalte",
      nodeDescription: "Block not yet configured",
      icon: EmailUnsubscribeIcon,
      shape: "Circle",
      actions: null,
      componentType: "atomic",
      type: "whatsapp",
      markup: util.svg/* xml */ `
              <rect @selector="body" />
              <foreignObject @selector="foreignObject" overflow="hidden">
                  <div @selector="content"
                      class="jj-form"
                      xmlns="http://www.w3.org/1999/xhtml"
                  >
                      <h2>Investment</h2>
                      <div class="jj-field-vertical">
                          <label>
                              How much did you invest?
                              <input @selector="funds" class="jj-input" type="number"/>
                          </label>
                      </div>
                      <div class="jj-field-vertical">
                          <label>
                              What year it was?
                              <select @selector="year" class="jj-input" type="number">
                                  <option value="2013">2013</option>
                                  <option value="2014">2014</option>
                                  <option value="2015">2015</option>
                                  <option value="2016">2016</option>
                                  <option value="2017">2017</option>
                                  <option value="2018">2018</option>
                                  <option value="2019">2019</option>
                                  <option value="2020">2020</option>
                                  <option value="2021">2021</option>
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                              </select>
                          </label>
                      </div>
                  </div>
              </foreignObject>
          `,
      ports: [
        {
          id: "emailActivityInput",
          group: "left",
        },
        {
          id: "unsubscribe-out",
          group: "right",
          attrs: {
            portLabel: {
              text: "Unsubscribe",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "email-activity": {
      title: "Email Activity",
      nodeDescription: "Block not yet configured",
      icon: EmailActivityIcon,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "emailActivityInput",
          group: "left",
        },
        {
          id: "emailActivity",
          group: "right",
          attrs: {
            portLabel: {
              text: "Email Activity",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "sms-activity": {
      title: "SMS Activity",
      nodeDescription: "Block not yet configured",
      icon: SMSActivityIcon,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "smsActivityInput",
          group: "left",
        },
        {
          id: "smsActivity",
          group: "right",
          attrs: {
            portLabel: {
              text: "SMS Activity",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "email-unsubscribe": {
      title: "Unsubscribe Email",
      nodeDescription: "Any email",
      icon: EmailUnsubscribeIcon,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "emailUnsubscribe-input",
          group: "left",
        },
        {
          id: "unsubscribe-out",
          group: "right",
          attrs: {
            portLabel: {
              text: "Unsubscribe",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "contact-updated": {
      title: "Contact Update",
      nodeDescription: "Any Contact Update",
      icon: ContactUpdate,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "contactUpdate-input",
          group: "left",
        },
        {
          id: "update",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Update",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "contact-added": {
      title: "Contact Created",
      nodeDescription: "Any Contact Created",
      icon: ContactCreatedIcon,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "contactUpdate-input",
          group: "left",
        },
        {
          id: "added",
          group: "right",
          attrs: {
            portLabel: {
              text: "on created",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "contact-added_to_list": {
      title: "Added To List",
      nodeDescription: "Block not yet configured.",
      icon: AddToList,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "added_to_list-input",
          group: "left",
        },
        {
          id: "added_to_list",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Added",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "datetime-scheduler": {
      title: "Date and Time Schedule",
      nodeDescription: "Block not yet configured.",
      icon: AddToList,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "added_to_list-input",
          group: "left",
        },
        {
          id: "added_to_list",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Added",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "contact-removed_from_list": {
      title: "Removed From List",
      nodeDescription: "Block not yet configured.",
      icon: RemoveFromList,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "removed_from_list-input",
          group: "left",
        },
        {
          id: "removed_from_list",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Removed",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "segment-added_to_segment": {
      title: "Added To Segment",
      nodeDescription: "Block not yet configured.",
      icon: AddedToSegment,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "added_to_segment-input",
          group: "left",
        },
        {
          id: "added_to_segment",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Removed",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "contact-field_match": {
      title: "Field Match",
      nodeDescription: "Block not yet configured.",
      icon: ContactFieldMatch,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "field_match-input",
          group: "left",
        },
        {
          id: "field_match",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Field Match",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "Datematch-date_match": {
      title: "Date Field Match",
      nodeDescription: "Block not yet configured.",
      icon: DateFieldMatch,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "date_match-input",
          group: "left",
        },
        {
          id: "date_match",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Date Field Match",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
    "application-submitted": {
      title: "Application Submit",
      nodeDescription: "Block not yet configured.",
      icon: AppliactionSubmittedIcon,
      shape: "Circle",
      actions: null,
      type: "web",
      ports: [
        {
          id: "application_submitted-input",
          group: "left",
        },
        {
          id: "application_submitted",
          group: "right",
          attrs: {
            portLabel: {
              text: "on Field Match",
            },
            ".joint-port-body": {
              fill: "#FF7452",
              width: "30px",
              height: "15px",
              borderRadius: "20px",
            },
          },
        },
      ],
    },
  },
  Condition: {
    "email-checkStatus": {
      title: "Check Email Status",
      nodeDescription: "Block not yet configured",
      icon: CheckEmailStatus,
      shape: "Hexagon",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "emailStatus-input",
          group: "left",
        },
        {
          id: "true",
          group: "right",
          attrs: {
            portLabel: {
              text: "Yes",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "false",
          group: "right",
          attrs: {
            portLabel: {
              text: "No",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
      ],
    },
    "sms-checkStatus": {
      title: "Check SMS Status",
      nodeDescription: "Block not yet configured",
      icon: CheckSmsStatus,
      shape: "Hexagon",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "smsStatus-input",
          group: "left",
        },
        {
          id: "true",
          group: "right",
          attrs: {
            portLabel: {
              text: "Yes",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "false",
          group: "right",
          attrs: {
            portLabel: {
              text: "No",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
      ],
    },
    "contact-query": {
      title: "Check Contact Field",
      nodeDescription: "Block not yet configured",
      icon: CheckContactField,
      shape: "Hexagon",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "query-input",
          group: "left",
        },
        {
          id: "true",
          group: "right",
          attrs: {
            portLabel: {
              text: "Yes",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "false",
          group: "right",
          attrs: {
            portLabel: {
              text: "No",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
      ],
    },
    "list-query": {
      title: "Check In List",
      nodeDescription: "Block not yet configured",
      icon: IsInList,
      shape: "Hexagon",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "isInList-input",
          group: "left",
        },
        {
          id: "true",
          group: "right",
          attrs: {
            portLabel: {
              text: "Yes",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "false",
          group: "right",
          attrs: {
            portLabel: {
              text: "No",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
      ],
    },
    "whatsapp-check_active_session": {
      title: "Whatsapp Active Session",
      nodeDescription: "Block not yet configured",
      icon: IsInSegment,
      shape: "Hexagon",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "isInSegment-input",
          group: "left",
        },
        {
          id: "true",
          group: "right",
          attrs: {
            portLabel: {
              text: "Yes",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "false",
          group: "right",
          attrs: {
            portLabel: {
              text: "No",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
      ],
    },
    "segment-query": {
      title: "Check In Segment",
      nodeDescription: "Block not yet configured",
      icon: IsInSegment,
      shape: "Hexagon",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "isInSegment-input",
          group: "left",
        },
        {
          id: "true",
          group: "right",
          attrs: {
            portLabel: {
              text: "Yes",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "false",
          group: "right",
          attrs: {
            portLabel: {
              text: "No",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
      ],
    },
  },
  Action: {
    "email-send": {
      title: "Send Email",
      nodeDescription: "Block not yet configured",
      icon: SendEmailIcon,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "sendEmailInput",
          group: "left",
        },
        {
          id: "sent",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Sent",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "delivered",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Delivered",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "opened",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Opened",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
        {
          id: "clicked",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Clicked",
              fill: "#8777D9",
            },
            ".joint-port-body": {
              fill: "#8777D9",
              stroke: "#8777D9",
            },
          },
        },
        {
          id: "unsubscribed",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Unsubscribed",
              fill: "#17274D",
            },
            ".joint-port-body": {
              fill: "#17274D",
              stroke: "#17274D",
            },
          },
        },
        {
          id: "bounced",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Bounced",
              fill: "#00C7E6",
            },
            ".joint-port-body": {
              fill: "#00C7E6",
              stroke: "#00C7E6",
            },
          },
        },
      ],
    },
    "delay-add": {
      title: "Add Delay",
      nodeDescription: "Block not yet configured",
      icon: DelayIcon,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "addDelayInput",
          group: "left",
        },
        {
          id: "afterDelay",
          group: "right",
          attrs: {
            portLabel: {
              text: "After Delay",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "sms-send": {
      title: "Send SMS",
      nodeDescription: "Block not yet configured",
      icon: SMSIcon,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "smsSend",
          group: "left",
        },
        {
          id: "sent",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Sent",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "delivered",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Delivered",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "failed",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Failed",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "contact-update": {
      title: "Update Contact",
      nodeDescription: "Block not yet configured",
      icon: ContactUpdateIcon,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "contactUpdate",
          group: "left",
        },
        {
          id: "updated",
          group: "right",
          attrs: {
            portLabel: {
              text: "Updated",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "email-send_user": {
      title: "Send Email To User",
      nodeDescription: "Block not yet configured",
      icon: EmailToUser,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "sendEmailtoUser",
          group: "left",
        },
        {
          id: "sent",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Sent",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "delivered",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Delivered",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "opened",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Opened",
            },
            ".joint-port-body": {
              fill: "#FF7452",
            },
          },
        },
        {
          id: "clicked",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Clicked",
              fill: "#8777D9",
            },
            ".joint-port-body": {
              fill: "#8777D9",
              stroke: "#8777D9",
            },
          },
        },
        {
          id: "unsubscribed",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Unsubscribed",
              fill: "#17274D",
            },
            ".joint-port-body": {
              fill: "#17274D",
              stroke: "#17274D",
            },
          },
        },
        {
          id: "bounced",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Bounced",
              fill: "#00C7E6",
            },
            ".joint-port-body": {
              fill: "#00C7E6",
              stroke: "#00C7E6",
            },
          },
        },
      ],
    },
    "sms-send_user": {
      title: "Send SMS To User",
      nodeDescription: "Block not yet configured",
      icon: SmsToTheUser,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "sendSMStoUser",
          group: "left",
        },
        {
          id: "sent",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Sent",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "delivered",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Delivered",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "failed",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Failed",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "list-add": {
      title: "Add to list",
      nodeDescription: "Block not yet configured",
      icon: AddToListIcon,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "list-add-input",
          group: "left",
        },
        {
          id: "list-add",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Added",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "list-remove": {
      title: "Remove from list",
      nodeDescription: "Block not yet configured",
      icon: RemoveFromListIcon,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "list-remove-input",
          group: "left",
        },
        {
          id: "list-remove",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Added",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "whatsapp-send": {
      title: "Send WhatsApp",
      nodeDescription: "Block not yet configured",
      icon: SendWhatsApp,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "whatsapp-send-input",
          group: "left",
        },
        {
          id: "sent",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Sent",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "delivered",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Delivered",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "seen",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Seen",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "replied",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Replied",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
        {
          id: "failed",
          group: "right",
          attrs: {
            portLabel: {
              text: "On Failed",
            },
            ".joint-port-body": {
              fill: "#4c9aff",
            },
          },
        },
      ],
    },
    "qrclass-can_generate_qr_code": {
      title: "Send QR to Contact",
      nodeDescription: "Block not yet configured",
      icon: SendQrIcon,
      shape: "Square",
      actions: "configure",
      type: "web",
      ports: [
        {
          id: "qrclass-input",
          group: "left",
        },
      ],
    },
  },
};
