import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormObserver,
  AppFormTimePickerMui,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { withLoader } from "../../../AppComponents/Loader";
import { DateMatchSchema } from "../validationSchema";
import moment from "moment";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import { saveGraph } from "../config";

const DateFieldMatch = ({ setLoading }: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formRef: any = useRef();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [dateOptions, setdateOptions] = useState([]);
  const [initialValues, setInitialValues]: any = useState({
    name: "",
    field_name: "",
    execution_cycle: "",
    date_of_execution: "",
    execution_offset: "",
    execution_time: moment.utc().toDate(),
  });

  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };

  useEffect(() => {
    dispatch(AutomationBuilderActions.getDateMatchOptions())
      .then((res: any) => {
        if (res?.payload?.data) {
          setdateOptions(
            res?.payload?.data?.data.map((item: any) => ({
              label: item.label,
              value: item.id,
              name: item.name,
            }))
          );
        }
      })
      .catch((error: any) => {});
  }, []);

  const executionOptions = [
    { id: 1, option_name: "Once" },
    { id: 2, option_name: "Anniversary" },
    // { id: 3, option_name: "Each time condition meets" },
  ];

  const dateOfOptions = [
    { id: 1, option_name: "On" },
    { id: 2, option_name: "After" },
    { id: 3, option_name: "Before" },
  ];

  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.field_name &&
      dateOptions?.length > 0
    ) {
      setInitialValues({
        ...initialValues,
        field_name:
          dateOptions.find(
            (ele: any) =>
              ele.name ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.field_name
          ) || "",
        execution_cycle:
          executionOptions.find(
            (ele: any) =>
              ele.option_name.toLowerCase() ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.execution_cycle
          ) || "",
        date_of_execution:
          dateOfOptions.find(
            (ele: any) =>
              ele.option_name.toLowerCase() ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.date_of_execution
          ) || "",
        execution_offset:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.execution_offset || "",
        execution_time: moment(
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.execution_time,
          "hh:mm A"
        )
          .utc()
          .toDate(),
      });
    }
  }, [automationPayload, dateOptions]);

  const handleSubmit = async (values: any, submitProps: any) => {
    const localTime = moment
      .utc(values.execution_time)
      .local()
      .format("hh:mm A");

    const fieldLabel = values.field_name?.label || "";
    const executionCycleLabel =
      values.execution_cycle?.option_name?.toLowerCase() || "";
    const dateOfExecutionLabel =
      values.date_of_execution?.option_name?.toLowerCase() || "";
    const executionOffset = values.execution_offset
      ? `${values.execution_offset} days `
      : "";

    const configText =
      `${fieldLabel} ${executionCycleLabel} ${dateOfExecutionLabel} ${executionOffset}at ${localTime}`.trim();

    let payload: any = {
      // key: "function",
      nodeObject: {
        field_name: values.field_name.name,
        execution_cycle: values.execution_cycle.option_name?.toLowerCase(),
        date_of_execution: values.date_of_execution.option_name?.toLowerCase(),
        execution_offset: Number(values?.execution_offset) || 0,
        execution_time: localTime,
      },

      nodeId: automationReduxData?.selectedNodeId,
      configText: configText,
    };
    // payload.function = "date_match";
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    await saveGraph();
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <ModelFormWrapper>
      <Typography variant="h6">Set Date Match</Typography>
      <AppForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={DateMatchSchema}
        innerRef={formRef}
      >
        {/* <AppFormField
          name={"name"}
          label={"Name"}
          placeholder={"Type Name"}
          // requiredSign={true}
        /> */}

        <AppReactSelectField
          name="field_name"
          options={dateOptions}
          label="Select Date Field"
          displayKey="label"
          valueKey="value"
          placeholder="Select Date Field"
          isRequired={true}
        />

        <AppReactSelectField
          name="execution_cycle"
          options={executionOptions}
          label="Execution Cycle"
          displayKey="option_name"
          valueKey="option_name"
          placeholder="Select Execution Cycle"
          isRequired={true}
        />

        <AppReactSelectField
          name="date_of_execution"
          options={dateOfOptions}
          label="Date of Execution"
          displayKey="option_name"
          valueKey="option_name"
          placeholder="Select Date Field"
          isRequired={true}
        />

        {formikProps?.values?.date_of_execution?.option_name !== "On" && (
          <AppFormField
            name={"execution_offset"}
            label={"Number of Days"}
            type="number"
            placeholder={"Enter Value"}
            requiredSign={true}
          />
        )}
        <AppFormTimePickerMui
          name={"execution_time"}
          label="Select time"
          utcTime={true}
          disablePast={true}
          requiredSign={true}
        />

        <AppFormObserver setFormikProps={setFormikProps} />
        <ModelFooter>
          <AppButton variant="outline" onClick={closeModel}>
            Cancel
          </AppButton>
          <AppSubmitButton
            title={"Save Config"}
            disabled={!formRef?.current?.isValid}
          />
        </ModelFooter>
      </AppForm>
    </ModelFormWrapper>
  );
};

export default withLoader(DateFieldMatch);

const ModelFormWrapper = styled.div`
  width: ${(props: any) => (props.isDataAvaliable ? "400px" : "500px")};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container {
    text-align: center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
