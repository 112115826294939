import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CORE_API_BASE_URL;
let automationBaseUrl = process.env.REACT_APP_CORE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";
automationBaseUrl = automationBaseUrl + "api/v1";

export class AutomationNetworkService {
  static getAutomationList(data: any) {
    const { limit, offset, search, tag, status } = data;
    const url = `${automationBaseUrl}${Endpoints.AUTOMATION_LIST}`;
    const params = { limit, offset, search, tag, status };

    return http.get(url, { params });
  }
  static deleteAutomation(id: any) {
    return http.delete(
      `${automationBaseUrl}${Endpoints.AUTOMATION_DEFAULT}/${id}`,
      {},
    );
  }
  static createAutomation(data: any) {
    return http.post(
      `${automationBaseUrl}${Endpoints.AUTOMATION_CREATE}`,
      data,
    );
  }
  static getAutomationById(id: string) {
    return http.get(`${automationBaseUrl}${Endpoints.AUTOMATION_BY_ID}/${id}`);
  }
  static updateAutomationById(id: string, payload: any) {
    return http.put(
      `${automationBaseUrl}${Endpoints.AUTOMATION_BY_ID}/${id}`,
      payload,
    );
  }
  static publishAutomationById(id: string) {
    return http.post(`${automationBaseUrl}${Endpoints.AUTOMATION_BY_ID}/${id}`);
  }

  static unPublishAutomationById(id: string) {
    return http.put(
      `${automationBaseUrl}${Endpoints.UNPUBLISH_AUTOMATION_BY_ID}/${id}`,
    );
  }

  static getModelConfigByType(type: string) {
    return http.get(`${consoleBaseUrl}${Endpoints.AUTOMATION_DEFAULT}/${type}`);
  }

  static getApplicationsList() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_APPLICATIONS_LIST}`);
  }
  static postAutomationFolder(data: any) {
    return http.post(`${automationBaseUrl}${Endpoints.CREATE_FOLDER}`, data);
  }

  static applyAutomationFolder(data: any) {
    return http.post(
      `${automationBaseUrl}${Endpoints.AUTOMATION_FOLDER_APPLY}`,
      data,
    );
  }
  static deleteAutomationFolder(data?: any) {
    return http.post(
      `${automationBaseUrl}${Endpoints.AUTOMATION_FOLDER_DELETE}`,
      data,
    );
  }

  static getDateMatchOptions(){
    return http.get(`${automationBaseUrl}${Endpoints.GET_DATE_MATCH}`);
  }
}

class Endpoints {
  static AUTOMATION_LIST = "/workspace/automation/list";
  static AUTOMATION_DEFAULT = "/workspace/automation";
  static AUTOMATION_CREATE = "/workspace/automation/create";
  static AUTOMATION_BY_ID = "/workspace/automation";
  static CREATE_FOLDER = "/workspace/create-automation-folder";
  static AUTOMATION_FOLDER_APPLY = "/workspace/add-automation-folders";
  static AUTOMATION_FOLDER_DELETE = "/workspace/remove-folder-from-automation";

  static UNPUBLISH_AUTOMATION_BY_ID = "/workspace/automation/unpublish";
  static GET_APPLICATIONS_LIST = "/workspace/automation/application-list";

  static GET_DATE_MATCH="/workspace/get-datetime-fields"
}
