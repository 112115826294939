import { useEffect } from "react";
import AppModel from "../../Hooks/useModel/AppModel";
import { useAppSelector } from "../../Store";
import {
  automationBuilderState,
  showModel,
} from "../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import SendWhatsApp from "./AutomationConfigForms/SendWhatsApp";
import AddDelay from "./AutomationConfigForms/addDelay";
import AddOrRemoveList from "./AutomationConfigForms/addOrRemoveList";
import AddedToList from "./AutomationConfigForms/contactAddedToList";
import AddedToSegment from "./AutomationConfigForms/contactAddedToSegment";
import ContactFieldMatch from "./AutomationConfigForms/contactFieldMatch";
import ContactUpdate from "./AutomationConfigForms/contactUpdate";
import EmailActivity from "./AutomationConfigForms/emailActivity";
import CheckEmailStatus from "./AutomationConfigForms/emailCheckStatus";
import EmailSend from "./AutomationConfigForms/emailSend";
import { ModelStyleWrapper } from "./AutomationConfigForms/formStyles";
import IsInList from "./AutomationConfigForms/isInList";
import IsInSegment from "./AutomationConfigForms/isInSegment";
import SMSActivity from "./AutomationConfigForms/smsActivity";
import CheckSMSStatus from "./AutomationConfigForms/smsCheckStatus";
import SmsSend from "./AutomationConfigForms/smsSend";
import AppliactionSubmitted from "./AutomationConfigForms/applicationSubmitted";
import SendQrToContact from "./AutomationConfigForms/sendQrToContact";
import DateTime from "./AutomationConfigForms/dateTime";
import WhatsappBotSession from "./AutomationConfigForms/whtasappBotSession";
import { saveGraph } from "./config";
import DateFieldMatch from "./AutomationConfigForms/dateMatch";

const ModelForms = (): JSX.Element => {
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const isModelVisible = useAppSelector(showModel);
  useEffect(() => {
    if (!isModelVisible) {
      saveGraph();
    }
  }, [isModelVisible]);

  const automationComponentMap: any = {
    "email-send": <EmailSend />,
    "delay-add": <AddDelay />,
    "sms-send": <SmsSend />,
    "email-send_user": <EmailSend />,
    "contact-update": <ContactUpdate />,
    "sms-send_user": <SmsSend />,
    "list-add": <AddOrRemoveList />,
    "list-remove": <AddOrRemoveList />,
    "email-checkStatus": <CheckEmailStatus />,
    "whatsapp-send": <SendWhatsApp />,
    "sms-checkStatus": <CheckSMSStatus />,
    "list-query": <IsInList />,
    "segment-query": <IsInSegment />,
    "email-activity": <EmailActivity />,
    "sms-activity": <SMSActivity />,
    "contact-added_to_list": <AddedToList />,
    "contact-removed_from_list": <AddedToList />,
    "segment-added_to_segment": <AddedToSegment />,
    "contact-field_match": <ContactFieldMatch />,
    "Datematch-date_match": <DateFieldMatch />,
    "contact-query": <ContactFieldMatch />,
    "application-submitted": <AppliactionSubmitted />,
    "qrclass-can_generate_qr_code": <SendQrToContact />,
    "datetime-scheduler": <DateTime />,
    "whatsapp-check_active_session": <WhatsappBotSession />
  };
  const selectedComponent =
    automationComponentMap[automationReduxData?.modelType];
  return (
    <AppModel isShowing={isModelVisible}>
      <ModelStyleWrapper>{selectedComponent || null}</ModelStyleWrapper>
    </AppModel>
  );
};
export default ModelForms;
